<template>
    <div class="settings">
        <van-nav-bar class="bar" :border="false" :title="$t('user.recharge')" left-arrow @click-left="onClickLeft" />

        <div style="height: 12px;"></div>
        <van-field class="settings-cell" type="digit" v-model="num" :placeholder="$t('recharge.placeholder1')" />

        <!--    <div class="upload">-->
        <!--      <p>{{ $t('recharge.placeholder2') }}：</p>-->
        <!--      <van-uploader v-model="fileList" :max-count="1" :max-size="6 * 1024 * 1024" @oversize="onOversize" :after-read="afterRead" @delete="_delete"/>-->
        <!--    </div>-->

        <div style="padding: 24px 12px;">
            <van-button class="submit-btn" type="primary" color="linear-gradient( 270deg, #A44AFB 0%, #F85DC6 100%)" block round @click="onSubmit" :loading="loading">{{ $t('user.recharge') }}</van-button>
        </div>
    </div>
</template>

<script>
import { recharge, uploadImg } from '@/api'
// import { IMAGE_URL } from '@/config'

export default {
    data() {
        return {
            num: '',
            loading: false,
            fileList: [],
            voucherImg: ''
        };
    },
    methods: {
        afterRead(file) {
            // 此时可以自行将文件上传至服务器
            console.log(file);
            const formData = new FormData();
            formData.append('image', file.file);
            uploadImg(formData).then(({ code, path }) => {
                console.log(path);
                if (code === 200) {
                    this.$toast(this.$t('recharge.upload_successfully'));
                    // this.fileList = [{ url: `${IMAGE_URL}${path}` }];
                    this.voucherImg = path
                }
            });
        },
        _delete() {
            this.voucherImg = ''
        },
        onOversize(file) {
            console.log(file);
            this.$toast(this.$t('recharge.tip1'));
        },
        onClickLeft() {
            this.$router.back();
        },
        onSubmit() {
            if (!this.num) {
                this.$toast(this.$t('recharge.placeholder1'));
                return;
            }
            // if (!this.voucherImg) {
            //   this.$toast(this.$t('recharge.placeholder2'));
            //   return;
            // }
            this.loading = true
            recharge({
                amount: this.num,
                voucher: this.voucherImg
            }).then(({ code }) => {
                this.loading = false
                if (code === 200) {
                    this.$toast(this.$t('recharge.submitted_successfully'));
                    setTimeout(() => {
                        this.$router.back();
                    }, 1500);
                } else {
                    this.$toast(this.$t('recharge.tip2'));
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
.upload {
    padding: 24px;
    background-color: #fff;
    > p {
        margin-bottom: 24px;
    }
}
.settings {
    min-height: 100vh;
    background: url(../../../assets/login_bg.png) left top no-repeat #fff;
    background-size: 100%;
    .bar {
        background: none;
    }
    .settings-cell {
        margin: 14px 16px;
        width: 343px;
        height: 48px;
        background: #ffffff;
        border-radius: 23px 23px 23px 23px;
    }
}
.bar /deep/ .van-nav-bar__left .van-icon {
    color: #333333;
}
@media only screen and (min-width: 750px) {
    .settings {
        background: none;
        padding: 42px;
        .bar {
            line-height: 30px;
            z-index: 0;
            /deep/ .van-nav-bar__content {
                height: 30px;
            }
            /deep/ .van-nav-bar__left {
                padding: 0;
                .van-icon {
                    font-size: 28px;
                }
            }
            /deep/ .van-nav-bar__title {
                font-size: 28px;
            }
        }
        .settings-cell {
            width: 100%;
            margin: 30px 0;
            height: 78px;
            line-height: 78px;
            background: #f0f3f8;
            border-radius: 16px;
            padding: 0 20px;
            font-size: 18px;
        }
        .submit-btn {
            width: 319px;
            height: 60px;
            line-height: 60px;
            background: linear-gradient(270deg, #a44afb 0%, #f85dc6 100%);
            border-radius: 55px 55px 55px 55px;
            font-size: 16px;
            margin: 0 auto;
        }
    }
}
</style>